// Change in Database names (tabs) should be reflected here
const categorize = ( category ) => {
    if ( category === "Meditations" ) return "meditations"
    if ( category === "Focus" ) return "focus"
    if ( category === "Thoughts" ) return "thoughts"
    if ( category === "Hypnosis" ) return "hypnosis"
    if ( category === "Breathwork" ) return "breathwork"
    if ( category === "Massages" ) return "massages"
}

module.exports = {
    categorize
}