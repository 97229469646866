import * as React from "react"
import { Link, graphql } from 'gatsby'
import { categorize } from "../helpers/helpers" 

import Layout from "../components/layout"

import { css } from '@emotion/react'

const NowPage = (props) => {
  const thought = props.data.thought
  const meditation = props.data.meditation
  const breath = props.data.breath
  const hypnosis = props.data.hypnosis
  const focus = props.data.focus

  const totalCount = thought.edges.length + meditation.edges.length + breath.edges.length + hypnosis.edges.length + focus.edges.length


  return (
    <Layout>
      <div className="container" css={container}>
        <h1>Now, here</h1>
        <p>This is your moment. Choose from {totalCount} activities!</p>

        {[thought, meditation, breath, hypnosis, focus].map( cat => {
          const category = categorize(cat.edges[0].node.table)

          return (
            <div key={category} css={section}>
              <h2 style={{"textTransform": "capitalize"}}>{category} ({cat.totalCount})</h2>
  
              <div css={grid}>
                {cat.edges.map( edge => {
                  const slug = edge.node.data.Slug
                  const name = edge.node.data.Name
  
                  return (
                    <div key={slug} css={activity}>
                      <Link to={`/${category}/${slug}`}>{name}</Link>
                      <span>{`/${category}/${slug}`}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default NowPage

const container = css`
  padding-top: 50px;
`

const section = css`
  margin-top: 50px;
`

const activity = css`
  padding: 30px 40px;
  // border: 1px solid #EBEBEB;
  border-radius: 5px;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  color: white;
  flex-direction: column;

  a {
    color: white;
    text-decoration: none;
  }

  span {
    font-size: 11px;
    margin-top: 10px;
    opacity: .66;
  }
`

const grid = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`

export const NowQuery = graphql`
  query NowQuery {
    thought: allAirtable(
      filter: {table: {eq: "Thoughts"}, data: {Status: {eq: "Done"}, Slug: {ne: "null"}}}
    ) {
      edges {
        node {
          id
          table
          data {
            Name
            Slug
          }
        }
      }
      totalCount
    }
    breath: allAirtable(
      filter: {table: {eq: "Breathwork"}, data: {Status: {eq: "Done"}, Slug: {ne: "null"}}}
    ) {
      edges {
        node {
          id
          table
          data {
            Name
            Slug
          }
        }
      }
      totalCount
    }
    hypnosis: allAirtable(
      filter: {table: {eq: "Hypnosis"}, data: {Status: {eq: "Done"}, Slug: {ne: "null"}}}
    ) {
      edges {
        node {
          id
          table
          data {
            Name
            Slug
          }
        }
      }
      totalCount
    }
    focus: allAirtable(
      filter: {table: {eq: "Focus"}, data: {Status: {eq: "Done"}, Slug: {ne: "null"}}}
    ) {
      edges {
        node {
          id
          table
          data {
            Name
            Slug
          }
        }
      }
      totalCount
    }
    meditation: allAirtable(
      filter: {table: {eq: "Meditations"}, data: {Status: {eq: "Done"}, Slug: {ne: "null"}}}
    ) {
      edges {
        node {
          id
          table
          data {
            Name
            Slug
          }
        }
      }
      totalCount
    }
  }
`